import { FC } from 'react';

import { Box, Grid, ListItemIcon, Typography } from '@mui/material';
import { getBasePath, rem } from '@otello/helpers';
import { themePalette } from '@otello/theme';
import { NavLink } from 'react-router-dom';

import { NavLinkBase } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';

export const NavigationMobile: FC = () => {
  return (
    <Grid
      component="nav"
      container
      height="100%"
      justifyContent="space-evenly"
      alignItems="start"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        height: rem(60),
        backgroundColor: 'surface.primary',
        borderTop: `1px solid ${themePalette.bg.primary}`,
        padding: rem(6, 0, 0),
      }}
    >
      {navRouter({ isMobile: true }).map(
        ({ path, name, icon, loadablePages }) => (
          <Grid item key={path} flexDirection="column">
            <NavLinkBase
              data-cy={`${getBasePath(path)}_link`}
              component={NavLink}
              disableTouchRipple
              to={path}
              onMouseOver={() => {
                loadablePages.preload();
              }}
              sx={{
                padding: 0,

                '&:hover': {
                  background: 'unset',
                },

                '& .MuiListItemIcon-root': {
                  marginRight: 'unset',
                },

                '&.active': {
                  background: 'unset',

                  '& .MuiTypography-root': {
                    color: 'basic.primary',
                  },
                },
              }}
            >
              <Box
                width={rem(64)}
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={rem(8)}
              >
                <ListItemIcon>{icon}</ListItemIcon>

                <Typography
                  variant="p5"
                  maxWidth={rem(60)}
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {name}
                </Typography>
              </Box>
            </NavLinkBase>
          </Grid>
        ),
      )}
    </Grid>
  );
};
