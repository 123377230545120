import loadable from '@loadable/component';
import { Bed, Book, Calendar, Info, Luggage } from '@otello/assets';
import { BASE_PAGES, pages, rem } from '@otello/helpers';

const RoomsPages = loadable.lib(() => import('@otello/pages/rooms'));
const MainPages = loadable.lib(() => import('@otello/pages/main'));
const RatesPages = loadable.lib(() => import('@otello/pages/rates'));
const BookingPages = loadable.lib(() => import('@otello/pages/booking'));
const PricesPages = loadable.lib(() => import('@otello/pages/prices'));

/** Отдаем меню навигации в нужном виде (мобайл/десктоп) **/
export const navRouter = ({ isMobile = false }: { isMobile?: boolean }) => {
  const iconSize = {
    width: rem(isMobile ? 20 : 24),
    height: rem(isMobile ? 20 : 24),
  };

  const checkerboardPageName = isMobile ? 'Цены' : 'Доступность и цены';
  const informationPageName = isMobile ? 'Об отеле' : 'Информация';

  return [
    {
      path: pages.MAIN,
      name: informationPageName,
      icon: <Info {...iconSize} />,
      loadablePages: MainPages,
      attributeValue: 'main',
    },
    {
      path: pages.ROOMS,
      name: 'Номера',
      icon: <Bed {...iconSize} />,
      loadablePages: RoomsPages,
      attributeValue: BASE_PAGES.ROOMS,
    },
    {
      path: pages.RATES,
      name: 'Тарифы',
      icon: <Book {...iconSize} />,
      loadablePages: RatesPages,
      attributeValue: BASE_PAGES.RATES,
    },
    {
      path: pages.PRICES,
      name: checkerboardPageName,
      icon: <Calendar {...iconSize} />,
      loadablePages: PricesPages,
      attributeValue: BASE_PAGES.PRICES,
    },
    {
      path: pages.BOOKING,
      name: 'Бронирования',
      icon: <Luggage {...iconSize} />,
      loadablePages: BookingPages,
      attributeValue: BASE_PAGES.BOOKING,
    },
  ] as const;
};
