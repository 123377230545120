import { PaletteOptions } from '@mui/material/styles';

export const themePalette: PaletteOptions = {
  basic: {
    primary: '#11100F',
    secondary: '#848382',
    tertiary: '#D2CECA',
  },
  accent: {
    success: '#1DB93C',
    warning: '#F6A31B',
    critical: '#FA5939',
  },
  surface: {
    primary: '#FFFFFF',
    secondary: '#F6F5F4',
  },
  bg: {
    primary: '#F6F5F4',
    secondary: '#DEECEF',
    tertiary: '#FFDADA',
  },
  button: {
    primary: {
      main: '#1DB93C',
      hover: '#1BAF39',
      active: '#1AA636',
    },
    surface: {
      main: '#FFFFFF',
      hover: '#F6F5F4',
      active: '#F6F5F4',
    },
    secondary: {
      main: '#EDEBEA',
      hover: '#E4E1DF',
      active: '#DBD7D4',
    },
    tertiary: {
      default: '#211F1E',
      hover: '#322F2C',
    },
    error: {
      main: '#FF5252',
      hover: '#F44C55',
    },
  },
  stroke: {
    default: '#DBD7D4',
    focus: '#024959',
    hover: '#C9C4BF',
    error: '#FF5252',
    input: {
      focus: '#2A6B74',
    },
  },
};
