import type { ElementType } from 'react';

import {
  generateUtilityClasses,
  styled,
  ListItemButton,
  Typography,
} from '@mui/material';
import type { ListItemButtonProps } from '@mui/material';
import Box from '@mui/material/Box';
import { rem } from '@otello/helpers';
import { BREAKPOINTS, marginRoot } from '@otello/theme';

export const classes = generateUtilityClasses('Header-Layout', [
  'root',
  'contacts',
  'navLinkBase',
]);

export const Root = styled(Box, {
  label: classes.root,
})(({ theme: { palette, shadows, breakpoints, zIndex } }) => ({
  height: rem(120),
  display: 'flex',
  justifyContent: 'center',
  background: palette.surface.primary,
  padding: rem(0, marginRoot),
  position: 'fixed',
  width: '100%',
  top: 0,
  zIndex: zIndex.header,
  borderBottom: `1px solid ${palette.bg.primary}`,

  [breakpoints.up(BREAKPOINTS.MD)]: {
    boxShadow: shadows[2],
  },

  [breakpoints.down(BREAKPOINTS.MD)]: {
    height: rem(56),
    padding: 0,
  },
}));

export const Wrapper = styled(Box, {
  label: classes.root,
})(() => ({
  height: rem(60),
  display: 'flex',
  alignItems: 'center',
}));

type NavLinkBaseProps<C extends ElementType<any> = any> = ListItemButtonProps<
  C,
  {
    component?: C;
  }
>;

export const NavLinkBase = styled(ListItemButton, {
  label: classes.navLinkBase,
})<NavLinkBaseProps>(({ theme: { palette, typography } }) => ({
  color: palette.basic.secondary,
  borderRadius: rem(8),
  padding: rem(6, 16, 6, 10),

  '&:hover': {
    background: palette.button.secondary.main,
  },

  '& .MuiListItemIcon-root': {
    minWidth: 0,
    color: palette.basic.tertiary,
    marginRight: rem(8),
  },

  '&.active': {
    background: palette.button.secondary.main,
    color: palette.basic.primary,
    '& .MuiListItemIcon-root': {
      color: palette.accent.success,
    },
    '& .MuiTypography-root': {
      color: palette.accent.success,
    },
  },

  ...typography.footnote,
}));

export const ContactsBoxStyled = styled(Box, {
  label: classes.contacts,
})(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: palette.bg.primary,
  borderRadius: rem(12),
  height: rem(40),
  padding: rem(0, 10),
}));

export const TypographyStyled = styled(Typography)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));
