import { Box, TextField, generateUtilityClasses, styled } from '@mui/material';
import { rem } from '@otello/helpers';

export const classes = generateUtilityClasses('Feedback', [
  'boxRating',
  'textField',
]);

export const BoxRating = styled(Box, {
  label: classes.boxRating,
  shouldForwardProp: (props) => props !== 'isEmpty',
})<{ isEmpty?: boolean }>(({ theme: { palette }, isEmpty }) => ({
  color: isEmpty ? palette.basic.tertiary : palette.accent.success,
  width: rem(56),
  height: rem(56),
  borderRadius: rem(12),
  backgroundColor: palette.bg.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const TextFieldStyled = styled(TextField, {
  label: classes.textField,
})(({ theme: { palette } }) => ({
  '& .MuiOutlinedInput-root': {
    fieldset: {
      borderColor: palette.stroke.default,
    },
    '&:hover fieldset': {
      borderColor: palette.stroke.hover,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px',
      borderColor: palette.stroke.focus,
    },
  },
  '.MuiInputBase-input': {
    fontSize: rem(16),
    fontWeight: 400,
    lineHeight: rem(20),
  },
  '.MuiInputBase-input::placeholder': {
    fontSize: rem(16),
    fontWeight: 400,
  },
}));
