import { Box, ListItemIcon, Typography } from '@mui/material';
import { pages, rem } from '@otello/helpers';
import { useAppSelector } from '@otello/store';
import { NavLink } from 'react-router-dom';

import { NavLinkBase, Wrapper } from '../../Header.styles';
import { navRouter } from '../../helpers/helpers';

export const NavigationDesktop = () => {
  const active = useAppSelector((state) => state.hotel.active);

  return (
    <Wrapper component="nav" justifyContent="space-between">
      {navRouter({ isMobile: false }).map(
        ({ path, name, icon, loadablePages, attributeValue }) => (
          <Box key={path}>
            <NavLinkBase
              data-cy={`${attributeValue}_link`}
              component={NavLink}
              to={path}
              onMouseOver={() => {
                loadablePages.preload();
              }}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              {name}

              {pages.ROOMS === path && (
                <Typography variant="p3" ml={rem(8)}>
                  {active?.amount.roomTypes}
                </Typography>
              )}

              {pages.RATES === path && (
                <Typography variant="p3" ml={rem(8)}>
                  {active?.amount.rates}
                </Typography>
              )}
            </NavLinkBase>
          </Box>
        ),
      )}
    </Wrapper>
  );
};
